import React from 'react';
import FormTinyPopupBudget from './FormTinyPopupBudget';
import { Invest } from '../../services/object/Invest';
import { Salepoint } from '../../services/object/Salepoint';

interface SalepointPopupBudgetChildrensProps {
    exist: boolean
    setExist: React.Dispatch<React.SetStateAction<boolean>>
    selectedSalepointBudget: string
    setSelectedSalepointBudget : React.Dispatch<React.SetStateAction<string>>
    selectedinvestBudget: string
    setSelectedinvestBudget : React.Dispatch<React.SetStateAction<string>>
    formRefBudget: React.RefObject<HTMLFormElement>
    TOSalepoint: Salepoint []
    TOInvestBudget: Invest[]
    errorMessages: Record<string, string>
    handleCloseTinyModalBudget: ()=>void
    getInvest: (investUid: string) => Promise<void>
    setTypeBudget: React.Dispatch<React.SetStateAction<boolean>>
    typeBudget : boolean
  }
    const investTinyPopupBudgetChildrens = ({ exist,
                                        setExist,
                                        selectedSalepointBudget,
                                        setSelectedSalepointBudget,
                                        selectedinvestBudget,
                                        setSelectedinvestBudget,
                                        formRefBudget,
                                        TOSalepoint,
                                        TOInvestBudget,
                                        errorMessages,
                                        handleCloseTinyModalBudget,
                                        getInvest,
                                        setTypeBudget,
                                        typeBudget
    } :SalepointPopupBudgetChildrensProps) => {

                 
    return (
            <FormTinyPopupBudget  exist={exist}
                                  setExist={setExist}
                                  selectedSalepointBudget={selectedSalepointBudget}
                                  setSelectedSalepointBudget={setSelectedSalepointBudget}
                                  selectedinvestBudget={selectedinvestBudget}
                                  setSelectedinvestBudget={setSelectedinvestBudget}
                                  formRefBudget={formRefBudget}
                                  TOSalepoint={TOSalepoint}
                                  TOInvestBudget={TOInvestBudget}
                                  errorMessages={errorMessages}
                                  handleCloseTinyModalBudget={handleCloseTinyModalBudget}
                                  getInvest={getInvest}
                                  setTypeBudget={setTypeBudget}
                                  typeBudget={typeBudget}/>
    )
}
export default investTinyPopupBudgetChildrens;