import React from 'react';
import FormTinyPopup from './FormTinyPopup';
import { Invest } from '../../services/object/Invest';
import { User } from '../../services/object/User';

interface SalepointPopupChildrensProps {
    handleButton: (state: number, comment?: string) => Promise<boolean>
    info: boolean
    setInfo: React.Dispatch<React.SetStateAction<boolean>>
    reject: boolean
    setReject: React.Dispatch<React.SetStateAction<boolean>>
    comment: string
    setComment: React.Dispatch<React.SetStateAction<string>>
    formRefState: React.RefObject<HTMLFormElement>
    errorMessages: Record<string, string>
    invest :Invest|undefined
    user :User
  }
    const investTinyPopupChildrens = ({handleButton,
                                    info,
                                    setInfo,
                                    reject,
                                    setReject,
                                    comment,
                                    setComment,
                                    formRefState,
                                    errorMessages,
                                    invest,
                                    user,
                                  } :SalepointPopupChildrensProps) => {

                 
    return (
            <FormTinyPopup  handleButton={handleButton}
                            info={info}
                            setInfo={setInfo}
                            reject={reject}
                            setReject={setReject}
                            comment={comment}
                            setComment={setComment}
                            formRefState={formRefState}
                            errorMessages={errorMessages}
                            invest={invest}
                            user={user}/>
    )
}
export default investTinyPopupChildrens;