
import style from './login.module.scss';
import myJraBg from '../../assets/img/bg-login-myJra.png';
// import GoogleButton from '../../components/googleAuth/GoogleButton';
import { FormEvent, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Service_Api } from '../../services/Api';
import { Button, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, Typography } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import PropTypes from 'prop-types';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const Login = ({ setToken }: any) => {
  const navigate = useNavigate();
  const [email, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    document.title = "MyJra - Login";
  }, []);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const Api = Service_Api();
  let url = new URLSearchParams(window.location.search);

  const handleLogin = async (e: React.MouseEvent<HTMLButtonElement> | FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    let body = {
      email: email,
      password: password
    }
    let data = (await (Api.post('user/connexion', body, {})))?.data
    if (data) {
      setError(false);
      let user = null;
      if (data.user)
        user = data.user

      let app = null;
      if (data?.app) {
        app = data.app
      }
      let law = null;
      if (data?.law) {
        law = data.law
      }
      let job = null;
      if (data?.job) {
        job = data.job
      }
      navigate("/connexion-en-cours",
        {
          state: {
            userPost: user,
            lawPost: law,
            appPost: app,
            jobPost: job
          }
        }
      );
    } else {
      setError(true)
      console.log(data)
    }
  };

  return (
    <div className={style.content}>
      <img src={myJraBg} className={style.bgMyJra} alt="logo" />

      <div className={style.login}>
        <div className={style.formBox}>
          <div className={style.header}>
            <h1>CONNEXION</h1>
            <h2>Bienvenue ! Connectez-vous pour accéder à MyJra :</h2>
          </div>
          <form autoComplete="off" action="" method="POST" onSubmit={(e) => { handleLogin(e) }}>

            {/* <div className={style.googleConnectButton}>
              <GoogleButton setToken={setToken} />
            </div>

            <h2>ou</h2> */}

            <Accordion className={style.accordion}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>Connexion avec une adresse e-mail</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TextField
                  id="email"
                  label="Addresse e-mail"
                  name="email"
                  autoComplete="email"
                  required
                  onChange={e => setUserName(e.target.value)}
                  error={error}
                  sx={{ m: 1, width: '100%' }}
                />
                <FormControl 
                  sx={{ m: 1, width: '100%' }} 
                  required
                >
                  <InputLabel htmlFor="outlined-adornment-password">Mot de passe</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end" >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    name="password"
                    autoComplete="password"
                    onChange={e => setPassword(e.target.value)}
                    label="Mot de passe"
                    required
                    error={error}
                  />
                </FormControl>
              </AccordionDetails>
            </Accordion>

            <div className="button-container">
              <Button type="submit"
                variant="contained"
                onClick={(e) => { handleLogin(e) }}>
                Connexion
              </Button>
            </div>
            <div>
              <Link to={'https://my.jra.tools/mot-de-passe'} title="Revenir à l'accueil">
                <span>Mot de passe oublié</span>
              </Link>
            </div>

          </form>
        </div>
      </div>
    </div>
  );
};

Login.propTypes = {
  setToken: PropTypes.func.isRequired
}

export default Login;