import { Button, FormControl, FormHelperText, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from 'react';
import dayjs from 'dayjs';
import { DatePicker, LocalizationProvider, frFR } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

interface BudgetTinyPopupChildProps {
    deleteAll: (year: string) => Promise<boolean>
    formRef: React.RefObject<HTMLFormElement>
    errorMessages: Record<string, string>
}
const FormTinyPopupBudget = ({
    deleteAll,
    formRef,
    errorMessages} :BudgetTinyPopupChildProps) => {
                                    
    const [localFormValues, setLocalFormValues] = useState({
        year : String((new Date()).getFullYear()),
    })     
         
    const handleLocalChange = (e: dayjs.Dayjs | null) => {
        setLocalFormValues((prevValues) => ({
            ...prevValues,
            year: String(e?.year())
        }));
    }
    return (
        <form ref={formRef} style={{display:"flex",flexDirection:"column"}}>
            <p>Seule l'année sera prise en compte:</p>
            <LocalizationProvider 
                                        dateAdapter={AdapterDayjs}
                                        adapterLocale="fr"
                                        localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}>
                <FormControl required >
                    <DatePicker label={'Année'} openTo="year" value={dayjs(localFormValues.year)} onChange={(e)=>handleLocalChange(e)}/>
                    <FormHelperText className={"error"}>{errorMessages.price}</FormHelperText>
                </FormControl>
            </LocalizationProvider>
            <Button variant="contained"
                    onClick={() => deleteAll(localFormValues?.year)} 
                    endIcon={(<DeleteIcon/> )}>
                Supprimer
            </Button>
        </form>
    )
}
export default FormTinyPopupBudget;