import styleModal from '../../components/tinyModal/tinyModal.module.scss';
import { Autocomplete, Button, Checkbox, FormControl, FormControlLabel, FormHelperText, TextField } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { SyntheticEvent} from 'react';
import { Invest } from '../../services/object/Invest';
import { Salepoint } from '../../services/object/Salepoint';
import { fakeObjectRadioBudgetType } from '../../services/object/FakeObject';
import RadioOnly from '../../components/input/RadioOnly';

interface SalepointTinyPopupChildBudgetProps {
    exist: boolean
    setExist: React.Dispatch<React.SetStateAction<boolean>>
    selectedSalepointBudget: string
    setSelectedSalepointBudget : React.Dispatch<React.SetStateAction<string>>
    selectedinvestBudget: string
    setSelectedinvestBudget : React.Dispatch<React.SetStateAction<string>>
    formRefBudget: React.RefObject<HTMLFormElement>
    TOSalepoint: Salepoint []
    TOInvestBudget: Invest[]
    errorMessages: Record<string, string>
    handleCloseTinyModalBudget: (openInvestPopup :boolean|undefined) => void
    getInvest: (investUid: string) => Promise<void>
    setTypeBudget: React.Dispatch<React.SetStateAction<boolean>>
    typeBudget : boolean
}
const FormTinyPopupBudget = ({exist,
                        setExist,
                        selectedSalepointBudget,
                        setSelectedSalepointBudget,
                        selectedinvestBudget,
                        setSelectedinvestBudget,
                        formRefBudget,
                        TOSalepoint,
                        TOInvestBudget,
                        errorMessages,
                        handleCloseTinyModalBudget,
                        getInvest,
                        setTypeBudget,
                        typeBudget
 } :SalepointTinyPopupChildBudgetProps) => {
                                    

    const handleSelectSalepointBudgetChange = (value: Salepoint|null) => {
        setSelectedSalepointBudget(value?.uid ?? "")
    }

    const handleSelectInvestBudgetChange = (value: Invest|null) => {
        setSelectedinvestBudget(value?.uid ?? "")
    }


    //event change on radio input
    const onChangeRadio = (budgetType: string) => {
        setTypeBudget(budgetType === "0")
    };
    
    return (
        <form ref={formRefBudget}>
            <p>
                Votre demande concerne un investissement prévu au budget immobilier ou hors immobilier ?
            </p>
            <div className={styleModal.items}>
                <Button variant="contained"
                        onClick={()=>(handleCloseTinyModalBudget(true))} 
                        startIcon={<NavigateBeforeIcon/>}
                        disabled={exist}>
                    NON
                </Button>
                <Button color='success' variant="contained"
                        onClick={()=>(setExist(true))} 
                        endIcon={<DoneIcon/>}
                        disabled={exist}>
                    OUI
                </Button>
            </div>
            <div className='popupContent oneLine'>
            {
                (exist) ?
                    <>
                        <FormControl>
                            <Autocomplete
                                fullWidth
                                options={TOSalepoint}
                                getOptionLabel={(salepoint)=>(salepoint.name)}
                                value={TOSalepoint.filter((salepoint:Salepoint)=>{
                                    if(selectedSalepointBudget === salepoint.uid) {
                                        return salepoint
                                    }
                                })[0]}
                                renderInput={(params) => <TextField {...params} label="Point de vente" />}
                                onChange={(e: SyntheticEvent<Element, Event>, value)=>(handleSelectSalepointBudgetChange(value))}
                                />
                            <FormHelperText className={"error"}>{errorMessages.salepoint}</FormHelperText>
                        </FormControl>
                        <FormControl >
                                <RadioOnly Objet={fakeObjectRadioBudgetType} 
                                    legend=""
                                    onChange={onChangeRadio}
                                    checkedUid={((typeBudget) ? "0" : "1")}
                                    disabled={false}
                                    required={true}
                                    display={"row"}
                                    name={"budget"}/>
                            <FormHelperText className='error'>{errorMessages.budget}</FormHelperText>
                        </FormControl>
                        {
                            (selectedSalepointBudget !== "" && TOInvestBudget) ? 
                            <>
                                <FormControl >
                                    <Autocomplete
                                        fullWidth
                                        options={TOInvestBudget}
                                        getOptionLabel={(invest)=>(invest.name + " (prev: "+ invest.price + " €)")}
                                        value={TOInvestBudget.filter((invest:Invest)=>{
                                            if(selectedinvestBudget === invest.uid) {
                                                return invest
                                            }
                                        })[0]}
                                        renderInput={(params) => <TextField {...params} label={"Investissement" + ((typeBudget) ? " immobilier" : " local")} />}
                                        onChange={(e: SyntheticEvent<Element, Event>, value)=>(handleSelectInvestBudgetChange(value))}
                                        />
                                    <FormHelperText className={"error"}>{errorMessages.salepoint}</FormHelperText>
                                </FormControl>
                                 
                            </>
                        :
                            ""
                        }
                    </>
                :
                    ""
            }
            </div>
            {
                (exist) ?
                    <div className={styleModal.items}>
                        <Button variant="contained"
                                onClick={()=>(setExist(false))} 
                                startIcon={<NavigateBeforeIcon/>}>
                            RETOUR
                        </Button>
                        <Button color='success' variant="contained"
                                onClick={()=>(getInvest(selectedinvestBudget))} 
                                endIcon={<DoneIcon/>}
                                disabled={(selectedinvestBudget === "")}>
                            CONTINUER
                        </Button>
                    </div>
                :
                    ""
            }
        </form>
    )
}
export default FormTinyPopupBudget;