import React from 'react';
import { Budget } from '../../services/object/Budget';
import { Category } from '../../services/object/Category';
import { Salepoint } from '../../services/object/Salepoint';
import FormPopupBudget from './FormPopupBudget';
import { Univers } from '../../services/object/Univers';

interface PopupChildrensProps {
    errorMessages: Record<string, string>,
    formRefBudget: React.RefObject<HTMLFormElement>,
    budget: Budget|undefined;
    TOCategory: Category[];
    TOSalepoint: Salepoint[];
    categoryUid: string;
    handleFormBudgetChange: (property: string, value: string| FileList | number | boolean | null) => void
  }
    const budgetPopupChildrens = ({errorMessages, 
                                    formRefBudget, 
                                    budget, 
                                    categoryUid,
                                    TOCategory,
                                    TOSalepoint,
                                    handleFormBudgetChange} :PopupChildrensProps) => {

                 
    return ([
                <FormPopupBudget  errorMessages={errorMessages}
                                  formRefBudget={formRefBudget}
                                  budget={budget}
                                  categoryUid={categoryUid}
                                  TOCategory={TOCategory}
                                  TOSalepoint={TOSalepoint}
                                  handleFormBudgetChange={handleFormBudgetChange}/>
           ]
    )
}
export default budgetPopupChildrens;