import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Accueil from './pages/Accueil/Accueil'
import './App.scss';
import { createTheme, ThemeProvider } from "@mui/material";
import NoMatch from "./pages/NoMatch/NoMatch";
import { useAuth } from "./services/auth/useAuth";
import Header from "./components/header/Header";
import { frFR } from '@mui/x-date-pickers/locales';
import AuthCrossOrigin from "./pages/CrossOrgin/AuthCrossOrigin";
import { App as AppJra  } from "./services/object/App";
import BudgetPage from "./pages/Budget/Budget";
import WaitingPage from "./pages/CrossOrgin/WaitingPage";
import Login from "./pages/Login/Login";
import useToken from "./services/auth/useToken";

const theme = createTheme(
  {
    palette: {
      primary: {
        main: '#008EC8',
        contrastText: "#FFFFFF",
      },
      secondary: {
        main: '#FF5252',
        contrastText: "#FFFFFF",
      },
      info: {
        main: '#585858',
        contrastText: "#FFFFFF",
      },
      success: {
        main: '#04AA6D',
        contrastText: "#FFFFFF",
      },
      error: {
        main: '#d63a5a',
        contrastText: "#FFFFFF",
      }
    },
  },
  frFR
); 

const createInvest = "6660860c8f1047.91483255";
const validInvest = "6660860c8f1347.91483206";

function App() {
  const { token, login, logout, setToken } = useAuth();
  //if dev cross origin fail because of :
  //https://developers.google.com/privacy-sandbox/cookies/storage-partitioning
  //so auto connection
  if (!token || token.length <= 0) {
    if(window.location.href.includes('dev') && window.location.href.includes('pages')){

      return (
        <ThemeProvider theme={theme}>
            <Router>
              <Routes>
                <Route path="*" element={<Login setToken={setToken}/>} />
                <Route path="/connexion-en-cours" element={<WaitingPage />} />
              </Routes>
            </Router>
        </ThemeProvider> 
      );
    }else{
        window.addEventListener("message",
          function (e) {
            console.log(e)
            if (e.origin !== 'https://my.jra.tools') { return; }
            let data = JSON.parse(e.data)
            if( data.token !== undefined && data.token !== ""){
              if(data.app.filter((app : AppJra) => (app.uid === "655caf9eb94a93.67329625")).length > 0){
                login(data.user, data.law, data.app, data.job, data.user.JWT)
              }else{
                logout();
                window.location.href = "https://my.jra.tools/deconnexion-crossOrigin?error="+btoa("Vous ne pouvez pas utiliser cette application.")+"&app="+btoa("Demande d'investissement");
              }
            }else{
              window.location.href = "https://my.jra.tools/login?auth="+btoa("true")+"&app="+btoa(window.location.href);
            }
        },false);
        return (
          <ThemeProvider theme={theme}>
              <Router>
                <Routes>
                  <Route path="*" element={<AuthCrossOrigin/>}/>
                </Routes>
              </Router>
          </ThemeProvider> 
        );
    }
  }

  document.title = "Demande investissement";
  return (
      <ThemeProvider theme={theme}>
          <meta name="google" content="notranslate"/>
          <Router>
            <Header/>
            <Routes>
              <Route path="/" element={<Accueil/>}/>
              <Route path="/accueil" element={<Accueil/>}/>
              <Route path="/budget" element={<BudgetPage/>}/>
              <Route path="*" element={<NoMatch/>}/>
              <Route
                  path="/connexion-en-cours"
                  element={<WaitingPage />}
                />
            </Routes>
          </Router>
      </ThemeProvider> 
  );
}

export default App;
