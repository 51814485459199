export const fakeObjectRadioBudget = [
    {
        uid:1,
        name: "au montant budgété"
    },
    {
        uid:2,
        name: "pour un montant supérieur au budget"
    },
    {
        uid:3,
        name: "pour un montant inférieur au budget"
    }
];

export const fakeObjectRadioRoi = [
    {
        uid:1,
        name: "Sécurité - Réglementaire"
    },
    {
        uid:2,
        name: "ROI < 1 an"
    },
    {
        uid:3,
        name: "ROI > 1 an"
    },
    {
        uid:4,
        name: "Pas de ROI"
    }
];


export const fakeObjectRadioBudgetType = [
    {
        uid:"0",
        name: "Budget immobilier"
    },
    {
        uid:"1",
        name: "Budget local"
    },
];