import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Visibility from '@mui/icons-material/Visibility';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';

import { Button, Chip, IconButton, Tab, Tabs } from "@mui/material";
import TableChart from "../../components/tables/Tablechart";
import ModalFormComponent from "../../components/modal/ModalFormComponent";
import Alert, { AlertProps } from "../../components/alert/Alert";
import Notification, { NotificationProps } from "../../components/notification/Notification";
import { useEffect, useRef, useState } from "react";
import { Category } from '../../services/object/Category';
import { Salepoint } from '../../services/object/Salepoint';
import Service_Api, { ResponseProps } from '../../services/Api';
import { Univers } from '../../services/object/Univers';
import { GridCallbackDetails, GridRenderCellParams, GridRowParams, GridValueFormatterParams, MuiEvent } from '@mui/x-data-grid';
import { jsDateToLocalFr, sqlToJsDate } from '../../services/tools/translateDate';
import { fakeObjectRadioBudget, fakeObjectRadioRoi } from "../../services/object/FakeObject";
import { Box } from '@mui/system';
import { useUser } from '../../services/auth/useUser';
import { Budget } from '../../services/object/Budget';
import { displayFormErrors } from '../../services/tools/errorForm';
import budgetPopupChildrens from './BudgetPopupChildrens';
import { Delete } from '@mui/icons-material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import TinyModalComponent from '../../components/tinyModal/TinyModalComponent';
import BudgetTinyPopupChild from './BudgetTinyPopupChild';

export default function BudgetPage() {

    const Api = Service_Api();

    const [displayAlert, setDisplayAlert] = useState<AlertProps>();
    const [displayNotif, setDisplayNotif] = useState<NotificationProps>();

    const { user } = useUser()
    const [dataRow, setDataRow] = useState<{budget :Budget, salepoint: Salepoint}[]>();
    const [max, setMax] = useState<number>(0);
    
    const [budget, setBudget] = useState<Budget>();
    const [categoryUid, setCategoryUid] = useState<string>("");
    const [TOCategory, setTOCategory] = useState<Category[]>([]);
    const [universUid, setUniversUid] = useState<string>("");
    const [TOUnivers, setTOUnivers] = useState<Univers[]>([]);
    const [TOSalepoint, setTOSalepoint] = useState<Salepoint[]>([]);
    const [loading, setLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [popupTitle, setPopupTitle] = useState<string>("Création nouveau Budget");
    const [errorMessages, setErrorMessages] = useState<Record<string, string>>({});
    const [popupChildren, setPopupChildren] = useState<JSX.Element[]>();
    const [tinyModalDeleteOpen, setTinyModalDeleteOpen] = useState(false);
    const [tinyPopupChild, setTinyPopupChil] = useState<JSX.Element>();
  
    const formRefBudget = useRef<HTMLFormElement>() as React.RefObject<HTMLFormElement>;
    const formRefDeleteAll = useRef<HTMLFormElement>() as React.RefObject<HTMLFormElement>;

    const columns = [
        { field: "JRA_budget.salepointUid", headerName: 'Point de vente', flex:1},
        { field: "JRA_budget.priceTotal", headerName: 'Budget total', flex:1},
        { field: "JRA_budget.priceTaken", headerName: 'Budget Restant', flex:1},
        { field: "JRA_budget.priceWaiting", headerName: 'Demande en cours', flex:1},
        { field: "JRA_budget.year", headerName: 'Exercice', flex:1},
        { field: "JRA_budget.type", headerName: 'Type', flex:1, type: 'string',
            valueFormatter: (params: GridValueFormatterParams<any>) => params.value,
            renderCell: (params: GridRenderCellParams<any>) => {
                switch (params.row['JRA_budget.type']) {
                    case '1':
                        return "Immobilier"
                    case '0':
                        return "Local"
                    default:
                        return "Local"
                }
            },
        },
        { field: 'action', headerName: 'Action', flex:1, type: 'boolean', filterable: false, sortable: false, disableExport: true, 
            renderCell: (params: GridRenderCellParams<any>) => (
            <>
                {
                    <>
                        <IconButton title={"Modifier"}>
                            <EditIcon/>
                        </IconButton>
                        <IconButton title={"Supprimer"} onClick={(e) => deleteLine(e, params)}>
                            <DeleteIcon/>
                        </IconButton>
                    </>
                }
            </>),
        },
    ];
    
      //event click on icon delete
      const deleteLine = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, params: GridRenderCellParams) =>{
        //desable onRowClick event
        event.preventDefault();
        event.stopPropagation();
        setDisplayAlert({
            open : true,
            title : "Suppression",
            contentText : "Êtes-vous sûr de vouloir supprimer ce budget ?",
            contentButtonSuccess : "Oui",
            contentButtonAbord : "Non",
            onSubmitSuccess:  async () => {
                //get current row id (= current budget id)
                let budgetUid = params.id as string
                let response = await Api.del("budget/", {uid : budgetUid});
                //reload table data
                let newData = dataRow?.filter(data => data.budget.uid !== budgetUid)
                setDataRow(newData)
                if(response?.success){
                    setDisplayNotif({
                        open : true,
                        contentText : "La demande a été supprimée",
                        severity : "success",
                        handleClose:  ()=>(setDisplayNotif(undefined))
                    })
                }else{
                    setDisplayNotif({
                        open : true,
                        contentText : "Aïe, une erreur inconnue est apparue. Merci de réessayer plus tard. (code erreur: "+response?.messages.code+")",
                        severity : "error",
                        handleClose:  ()=>(setDisplayNotif(undefined))
                    })
                }
                setDisplayAlert(undefined)
            },
            onSubmitAbord:  () => (setDisplayAlert(undefined))
        })
        
    }

    const handleFormBudgetChange = (property: string, value: string| FileList | number | boolean | null) => {
        
        if(typeof value === "string" && property === "priceTotal" && value){
            value = value.replace(/ /g,'')
            value = value.replace(/€/g,'')
        }
        setBudget((budgetValue : any) => ({
            ...budgetValue,
            [property]: value,
        }));
    }

    /* hydrate data onload */
    useEffect(() => {
        fecthData()
    }, []);

    //reset children data when budget change
    useEffect(() => {
        let children = budgetPopupChildrens({errorMessages, 
                                            formRefBudget, 
                                            budget, 
                                            categoryUid,
                                            TOCategory,
                                            TOSalepoint,
                                            handleFormBudgetChange
                                        });
        setPopupChildren(children)
    }, [budget, errorMessages, categoryUid, , TOSalepoint, TOUnivers, universUid]);
  
  const fecthData = async() => {
        //get all budget (for row data)
        let response = await Api.get("category/");
        if(response?.success)
            setTOCategory(response.data);
        response = await Api.get("salepoint/getSalepointByUserUid");
        if(response?.success){
            setTOSalepoint(response.data);
        }
        response = await Api.get("univers/getUniversByUserUid");
        if(response?.success)
            setTOUnivers(response.data);
    }

    const getBudget = async(budgetUid:string) => {
        //get budget by uid
        let response = await Api.get("budget/", "uid="+budgetUid);
        //set current budget (to hydrate popup's input)
        setBudget(response?.data);
        
        setModalOpen(true);
        
    }

    //event click on row
    const onRowClick = (params: GridRowParams, event: MuiEvent, details: GridCallbackDetails) => {
        let budgetUid = params.id as string
        getBudget(budgetUid)
        setPopupTitle("La demande")
    }
    //event click on card
    const onCardClick = (budgetUid:string) => {        
        getBudget(budgetUid)
        setPopupTitle("La demande")

    }

  const getRowData = async (paginationModel: { page: number; pageSize: number;}, sort: {}, filter: {}, newState? : string) => {
        // fetch data from server
        setLoading(true)
        const response = await Api.post("budget/getAllBudgetForYear", {
            page: paginationModel.page,
            pageSize: paginationModel.pageSize,
            sort,
            filter,
            categoryUid : ((newState === undefined) ? null : newState)
        });
        if(response?.success){
            setDataRow(response?.data);
            setMax(response?.recordsTotal ?? 0);
            setLoading(false)
        }else{
            setDataRow([]);
            setMax(0);
            setLoading(false)
        }
    };

  
    //event on click button create
    const handleOpenModal = () => {
        setModalOpen(true);
        setBudget((budgetValue : any) => ({
            ...budgetValue,
            ['userUid']: user.uid,
        }));
        setDisplayAlert(undefined);
        //reset input's data
    };
    
    //event on click icon close
    const handleCloseModal = () => {
        setModalOpen(false);
        //reset input's data
        resetPopup();
    };
    
    //event on click icon close
    const handleCloseModalBudget = () => {
        setModalOpen(false);
        //reset input's data
        resetPopup();
    };
    
    const resetPopup = () => {
        setBudget(undefined)
        setCategoryUid("")
        setUniversUid('')
        setErrorMessages({})
        setPopupTitle("Créer une demande")
    }

    const onFormSubmitSuccess = async () => {
        let error = displayFormErrors(formRefBudget);
        if(Object.keys(error).length === 0){
            let response : ResponseProps|undefined;

            if(budget?.uid){
                let newBudget = budget;
                response = await Api.put("budget/", newBudget);
                let oldData = dataRow;
                if(oldData){
                    const index = oldData.findIndex(data => data.budget.uid === budget.uid);
                    // oldData = oldData.filter(data => data.budget.uid !== budget.uid)
                    oldData[index].budget = budget;
                    setDataRow(oldData);
                }                
            //else create it
            }else{
                console.log(budget)
                response = await Api.post("budget/", {
                    uid: budget?.uid,
                    year: (budget?.year ?? (new Date()).getFullYear() + 1),
                    type: budget?.type,
                    salepointUid: budget?.salepointUid,
                    priceTotal: budget?.priceTotal,
                });
            }
            if(response?.success){
                //reload table data
                let newData = dataRow;
                newData?.unshift(response?.data)
                setDataRow(newData);
                //set new current budget
                setBudget(response?.data.budget)
                setDisplayNotif({
                    open : true,
                    contentText : "Demande enregistrée",
                    severity : "success",
                    handleClose:  ()=>(setDisplayNotif(undefined))
                })  
                handleCloseModal();
            }else{
                let text = "Une erreur est surevnu. Code :" +response?.messages.code;
                if(response?.messages.code == 1116){
                    text = "Un budget est déjà créé pour cette concession et cet exercice.";
                }
                
                setDisplayNotif({
                    open : true,
                    contentText : text,
                    severity : "error",
                    handleClose:  ()=>(setDisplayNotif(undefined))
                })
                return false;
            }
            return true;
        }else{
            setErrorMessages(error);
            return false;
        }
    }
    const handleOpenPagebudget = () => {
        window.location.href = "/";
    }

    const deleteAll = async (year : string) => {
        let response = await Api.del("budget/deleteAllByYear", {year : year});
        if(response?.success){
            closeTinyPopup()
            setTimeout(function(){
                window.location.reload();
            }, 3000)
            setDisplayNotif({
                open : true,
                contentText : "Budgets supprimés. La page va bientôt s'actualiser.",
                severity : "success",
                handleClose:  ()=>(setDisplayNotif(undefined))
            })
        }else{
            setDisplayNotif({
                open : true,
                contentText : "aïe, ça à loupé: "+ response?.messages,
                severity : "error",
                handleClose:  ()=>(setDisplayNotif(undefined))
            })
        }
        return response?.success ?? false;
    }

    const openTinyPopup = () => {
        setTinyModalDeleteOpen(true)
    }

    const closeTinyPopup = () => {
        setTinyModalDeleteOpen(false)
    }

    useEffect(() => {
        let child = BudgetTinyPopupChild({
            deleteAll, 
            formRefDeleteAll,
            errorMessages
        });
        setTinyPopupChil(child)
    }, [errorMessages]);

    return (
        <div className="background">
          <div className="backsquare">
          <h1 className='title2'>Gestion des budgets</h1>
            <div className="tableContainer">
                <div className="buttonContainer">
                    <Button  className="addbutton" 
                            variant="contained" 
                            color="secondary"
                            sx={{width:260, height:50}} 
                            onClick={handleOpenPagebudget} 
                            endIcon={<PriceCheckIcon/>}> 
                        Gérer les demandes
                    </Button>
                    <Button  className="addbutton" 
                            variant="contained" 
                            color='info'
                            sx={{width:260, height:50}} 
                            onClick={openTinyPopup} 
                            endIcon={<DeleteForeverIcon/>}> 
                        Supprimer
                    </Button>
                    <Button  className="addbutton" 
                            variant="contained" 
                            sx={{width:260, height:50}} 
                            onClick={handleOpenModal} 
                            endIcon={<RequestQuoteIcon/>}> 
                        Ajouter un budget
                    </Button>
                </div>
                
                <TableChart
                    //onTableChange event do this func
                    callBackFunctionGetRowData={getRowData} 
                    //send new data to table and map to match structure of datagrid row
                    dataRow={dataRow?.map(
                        (data:{budget: Budget, salepoint: Salepoint}) => {
                            return  { 
                                "id" : data.budget.uid,
                                "JRA_budget.priceTotal" : String(data.budget.priceTotal).replaceAll('.', ',') +" €",
                                "JRA_budget.priceTaken" : String(data.budget.priceTotal - data.budget.priceTaken).replaceAll('.', ',') +" €",
                                "JRA_budget.priceWaiting" : String(data.budget.priceWaiting).replaceAll('.', ',') +" €",
                                "JRA_budget.salepointUid" :  data.salepoint?.name,
                                "JRA_budget.year" :  data.budget?.year,
                                "JRA_budget.type" :  data.budget?.type,
                            }
                        }
                    )}
                    columns={columns} 
                    columnVisibility = {{}}
                    onRowClick={onRowClick}
                    onCardClick={onCardClick}
                    handleDeleteRow={(e)=>(true)}
                    loading={loading}
                    max={max}
                    />
                <ModalFormComponent
                    title={popupTitle}
                    modalOpen={modalOpen} 
                    onClose={handleCloseModal} 
                    onFormSubmitSuccess={onFormSubmitSuccess} 
                    childrenForm={popupChildren}
                    formEvent={[onFormSubmitSuccess]}
                    buttonText={"Terminer"} 
                    buttonIcon={<KeyboardDoubleArrowRightIcon/>}/>
                <TinyModalComponent
                                title={"Supprimer les budgets de l'année sélectionnée"}
                                modalOpen={tinyModalDeleteOpen} 
                                onClose={closeTinyPopup} 
                                child={tinyPopupChild}
                                progess={false}/>
                {
                    (displayAlert?.open === true) ? <Alert {...displayAlert}/>: ""
                }
                {
                    (displayNotif?.open === true) ? <Notification {...displayNotif}/>: ""
                }
                </div>
            </div>
        </div>
    )
}