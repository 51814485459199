import React from 'react';
import FormTinyPopup from './FormTinyPopup';

interface SalepointPopupChildrensProps {
    deleteAll: (year: string) => Promise<boolean>
    formRefDeleteAll: React.RefObject<HTMLFormElement>
    errorMessages: Record<string, string>
  }
    const BudgetTinyPopupChild = ({deleteAll,
                                  formRefDeleteAll,
                                  errorMessages,
                                  } :SalepointPopupChildrensProps) => {

                 
    return (
            <FormTinyPopup deleteAll={deleteAll}
                            formRef={formRefDeleteAll}
                            errorMessages={errorMessages}/>
    )
}
export default BudgetTinyPopupChild;