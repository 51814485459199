import { useState } from "react";
import useToken from "./useToken";
import { useUser } from "./useUser";
import { User } from "../object/User";
import { App } from "../object/App";
import { useApp } from "./useApp";
import { Law } from "../object/Law";
import { useLaw } from "./useLaw";
import { Job } from "../object/Job";
import { useJob } from "./useJob";

export const useAuth = () => {
  const { token, setToken, removeToken } = useToken();
  const [isConnected, setIsConnected]  = useState(true);
  const {user, setUser, removeUser}  = useUser();
  const {job, setJob, removeJob}  = useJob();
  const {TOApp, setTOApp, removeTOApp} = useApp();
  const {law, setLaw, removeLaw}  = useLaw();

  const logout = () => {
    removeToken();
    removeUser();
    removeLaw();
    removeTOApp();
    removeJob()
    setIsConnected(false)
  };

  const login = (user: User, law : Law[], TOApp: App[], job: Job, token :string) => {
    setUser(user);
    setLaw(law);
    setTOApp(TOApp);
    setJob(job)
    setToken(token)
    setIsConnected(true)
  };

  const updateUser = (updatedUser: User) => {
    setUser(updatedUser);
  };

  return { isConnected , user, setUser, law, TOApp, logout, login, updateUser, token, setToken };
};
