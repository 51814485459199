import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import FormLabel from '@mui/material/FormLabel';
import style from '../modal/checkbox.module.scss';
// import style_salepoint from '../../pages/Salepoint/salepoint.module.scss';
import { RadioGroup } from '@mui/material';
import { OverridableStringUnion } from '@mui/types';

interface RadioProps {
  legend: string;
  onChange: (object: string) => void;
  Objet: any;
  checkedUid: string|undefined;
  required:boolean,
  disabled:boolean,
  display ?:OverridableStringUnion<'row' | 'column'>;
  name:string;
}

export default function RadioOnly({ Objet, legend, onChange, checkedUid, required, disabled, display, name}: RadioProps) {
  return (
      <RadioGroup sx={{flexDirection: display}}
                  name={name}>
        {legend}
        {Objet.map((object: any) => (
            <FormControlLabel
                control={<Radio value={object.uid} required={required}/>}
                key={object.uid}
                name={name}
                label={object.name} 
                checked={((checkedUid && checkedUid === object.uid.toString()) ? true : false)}
                disabled={disabled}
                onChange={() => onChange(object.uid)}
                />
        ))}
      </RadioGroup>
  );
};
