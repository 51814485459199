import styleModal from '../../components/modal/modal.module.scss';
import { Autocomplete, Button, Checkbox, Chip, FormControl, FormControlLabel, FormGroup, FormHelperText, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, TextField, Typography } from '@mui/material';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Budget } from '../../services/object/Budget';
import { Category } from '../../services/object/Category';
import { Salepoint } from '../../services/object/Salepoint';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { NumericFormatCustom } from '../../components/input/NumericInput';

import style from './budget.module.scss';

interface SalepointPopupChildrensProps {
    errorMessages: Record<string, string>,
    formRefBudget: React.RefObject<HTMLFormElement>,
    budget: Budget|undefined;
    TOCategory: Category[];
    TOSalepoint: Salepoint[];
    categoryUid: string;
    handleFormBudgetChange: (property: string, value: string | FileList | null |number | boolean ) => void
  }
    const FormPopup = ({errorMessages, 
                        formRefBudget, 
                        budget, 
                        TOSalepoint,
                        handleFormBudgetChange
                        } :SalepointPopupChildrensProps) => {
                            

  const [localFormValues, setLocalFormValues] = useState({
                                                            uid : "",
                                                            salepointUid : "",
                                                            priceTotal : 0,
                                                            priceTaken : 0,
                                                            priceWaiting : 0,
                                                            year: 0,
                                                            type:  0
                                                        })     

  useEffect(() => {
    if (budget) {
        setLocalFormValues({
                uid : budget?.uid ?? "",
                salepointUid : budget?.salepointUid ?? "",
                priceTotal : budget?.priceTotal ?? 0,
                priceTaken : budget?.priceTaken ?? 0,
                priceWaiting : budget?.priceWaiting ?? 0,
                year:  budget?.year ??  (new Date()).getFullYear() + 1,
                type:  budget?.type ?? 0
            });
    }
  }, [budget]) 
    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        handleFormBudgetChange(name, value);
    }
  
    const handleChangSalepoint= (value : Salepoint|null) => {
        let name = "salepointUid";
        handleFormBudgetChange(name, value?.uid ?? "");
    };

    const handleLocalChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let { name, value } = e.target;
        setLocalFormValues((prevValues) => ({
          ...prevValues,
          [name]: value,
        }));
      }

    return (<form className={style.uiform} ref={formRefBudget}>
                <h2  className={styleModal.subtitle}>Les informations générales :</h2>
                <div className={style.contentText}>
                    <FormControl >
                        <Autocomplete
                            options={TOSalepoint}
                            getOptionLabel={(salepoint)=>(salepoint.name)}
                            value={TOSalepoint.filter((salepoint:Salepoint)=>{
                                
                                if(localFormValues?.salepointUid === salepoint.uid) {
                                    console.log(localFormValues)
                                    return salepoint
                                } 
                            })[0] || null}
                            renderInput={(params) => <TextField {...params} label="Point de vente" />}
                            onChange={(e: SyntheticEvent<Element, Event>, value)=>(handleChangSalepoint(value))}
                            />
                            <FormHelperText className={style.error}>{errorMessages.salepoint}</FormHelperText>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel id="type-label">Type</InputLabel>
                        <Select
                            id="type"
                            value={budget?.type ?? "0"}
                            label="type"
                            onChange={((e)=>(handleFormBudgetChange('type', e.target.value)))}
                        >
                            <MenuItem value={"0"}>Local</MenuItem>
                            <MenuItem value={"1"}>Immobilier</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField label="Budget Total"  
                        size="medium"
                        value={localFormValues.priceTotal.toString().replace(/\d{3}(?=.)/g, '$& ')}
                        name="priceTotal"
                        onBlur={handleBlur}
                        onChange={(e) => handleLocalChange(e)} required
                        error={!!errorMessages.price}
                        helperText={errorMessages.price}
                        InputProps={{
                            inputComponent: NumericFormatCustom as any,
                        }}/>
                    <TextField label="Année"  
                        size="medium"
                        value={localFormValues.year}
                        name="year"
                        onBlur={handleBlur}
                        onChange={(e) => handleLocalChange(e)} required
                        error={!!errorMessages.year}
                        helperText={errorMessages.year}/>
                </div>
            </form>)
}
export default FormPopup;